import type { PageBlock } from '~/types';

export function decodeWPData(data: string) {
  try {
    return JSON.parse(decodeURIComponent(data));
  } catch {
    return null;
  }
}

export function decodeWPBlock(data: PageBlock) {
  if (!data.attributes) {
    return {
      ...data,
      attributes: {},
    };
  }

  const decodedData: any = {};
  Object.keys(data.attributes).forEach((attr) => {
    if (typeof data.attributes[attr] === 'string' && data.attributes[attr] !== '') {
      Object.assign(decodedData, { [attr]: decodeWPData(data.attributes[attr]) || data.attributes[attr] });
    } else if (data.attributes[attr] !== '') {
      Object.assign(decodedData, { [attr]: data.attributes[attr] });
    }
  });

  return {
    ...data,
    attributes: decodedData,
  };
}
