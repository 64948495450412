<script setup lang="ts">
import { onClickOutside } from '@vueuse/core';

const emit = defineEmits(['toggle']);

const dropdownEl = ref<HTMLDivElement>();
const showDropdown = ref<boolean>(false);

watch(showDropdown, (newValue) => {
  emit('toggle', newValue);
});

function closeDropdown() {
  showDropdown.value = false;
}

function openDropdown() {
  showDropdown.value = true;
}

onClickOutside(dropdownEl, () => {
  showDropdown.value = false;
});
</script>

<template>
  <div
    v-bind="$attrs"
    ref="dropdownEl"
    class="dropdown-container relative"
    :class="$attrs.class"
    @mouseenter="showDropdown = true"
    @mouseleave="showDropdown = false"
  >
    <div
      class="dropdown-button bg-transparent"
      :aria-expanded="showDropdown"
    >
      <slot
        name="button"
        :is-open="showDropdown"
        :close="closeDropdown"
        :open="openDropdown"
      />
    </div>
    <div class="dropdown-content hidden z-dropdown absolute">
      <slot
        name="dropdown"
        :is-open="showDropdown"
        :close="closeDropdown"
        :open="openDropdown"
      />
    </div>
  </div>
</template>

<style scoped>
.dropdown-container:hover .dropdown-content,
.dropdown-button[aria-expanded='true'] + .dropdown-content {
  display: block;
}
</style>
